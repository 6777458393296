import React from 'react';
import classNames from 'classnames';

export class Asset extends React.Component {
  constructor (props) {
    super();
    this.state = {};
  }

  get className () {
    return classNames({
      'asset': true,
      [this.props.className]: true
    });
  }

  render = () => {
    if (!this.props.asset.file) return <React.Fragment/>;
    if (!this.props.asset.file.contentType) return <React.Fragment/>;
    if (!this.props.asset.file.url) return <React.Fragment/>;

    if (this.props.asset.file.contentType.indexOf('image') > -1) return this.renderImage();
    if (this.props.asset.file.contentType.indexOf('video') > -1) return this.renderVideo();
  }

  renderImage = () => (
    <picture className={this.className}>
      <source media="(max-resolution: 1dppx) and (max-width:400px)" srcSet={this.props.asset.file.url + '?w=400'} />
      <source media="(max-resolution: 1dppx) and (max-width:769px)" srcSet={this.props.asset.file.url + '?w=769'} />
      <source media="(max-resolution: 1dppx) and (max-width:1025px)" srcSet={this.props.asset.file.url + '?w=1025'} />
      <source media="(max-resolution: 1dppx) and (max-width:1250px)" srcSet={this.props.asset.file.url + '?w=1250'} />
      <source media="(max-resolution: 1dppx) and (max-width:1400px)" srcSet={this.props.asset.file.url + '?w=1400'} />
      <source media="(max-resolution: 1dppx) and (min-width:1400px)" srcSet={this.props.asset.file.url + '?w=2500'} />

      <source media="(min-resolution: 2dppx) and (max-width:400px)" srcSet={this.props.asset.file.url + '?w=800'} />
      <source media="(min-resolution: 2dppx) and (max-width:769px)" srcSet={this.props.asset.file.url + '?w=1200'} />
      <source media="(min-resolution: 2dppx) and (max-width:1025px)" srcSet={this.props.asset.file.url + '?w=2000'} />
      <source media="(min-resolution: 2dppx) and (min-width:1250px)" srcSet={this.props.asset.file.url + '?w=2000'} />
      <source media="(min-resolution: 2dppx) and (min-width:1400px)" srcSet={this.props.asset.file.url + '?w=2500'} />
      <source media="(min-resolution: 2dppx) and (min-width:1800px)" srcSet={this.props.asset.file.url + '?w=3000'} />

      <img src={this.props.asset.file.url + '?w=1200'}  width={this.props.asset.file?.details?.image?.width} height={this.props.asset.file?.details?.image?.height} />
    </picture>
  )

  renderVideo = () => (
    <picture className={this.className}>
      <video playsInline muted loop autoPlay src={this.props.asset.file.url} />
    </picture>
  )
}