import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';

export class HomepageGradeBlock extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      activeSlide: 0,
      dimension: 800,
      loaded: false
    };
  }

  get className () {
    return classNames({
      'homepage-grade-block': true
    });
  }

  componentDidMount () {
    setInterval(this.next, 800);
    if (window.innerWidth > 700) this.setState({ dimension: 1200 });
    setTimeout(this.loadOthers, 500);
  }

  next = () => {
    var next = this.state.activeSlide + 1;
    if (next === this.props.tileImages.length) next = 0;
    this.setState({ activeSlide: next });
  }

  loadOthers = () => {
    this.setState({ loaded: true });
  }

  uri (image) {
    return [
      image.file.url + '?fm=jpg',
      'w=' + this.state.dimension,
      'h=' + this.state.dimension,
      'fit=fill',
      'q=60'
    ].join('&');
  }

  render = () => (
    <Link className={this.className} to={`/${this.props.language}/${this.props.year}/${this.props.grade}`}>
      <div className="homepage-grade-block__image">
        { this.props.tileImages && this.props.tileImages.map(
          (image, index) => {
            if (!image.file?.url) return <React.Fragment />;
            if (!this.state.loaded && index > 0) return <React.Fragment />;
            return <img style={{ opacity: index === this.state.activeSlide ? 1 : 0 }} width="1200" height="1200" src={this.uri(image)} />
          }
        )}
      </div>
      <div className="homepage-grade-block__description">
        {this.props.description}
      </div>
      <div className="homepage-grade-block__number">  
        <span>{this.props.grade}</span>
      </div>
    </Link>
  )
}