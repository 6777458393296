import React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { Header } from '../../components/header';
import { HomepageIntro } from '../../components/homepage-intro';
import { HomepageGradeBlock } from '../../components/homepage-grade-block';
import { Link } from 'gatsby';

export default class Page extends React.Component {
  constructor () {
    super();
    this.state = {};
  }

  get className () {
    return classNames({
      'homepage': true
    });
  }

  get website () {
    return this.props.pageContext.website;
  }

  get year () {
    return this.website.years[0];
  }

  render = () => (
    <>
     <Helmet>
        <title>{ this.website.title }</title>
        <meta name="description" content={this.website.description} />
        <meta property="og:image" content={'https:' + this.website.socialMediaImage.file.url} />
      </Helmet>
      <Header website={this.website} />
      <HomepageIntro
        href={`/${this.website.language}/${this.year.year}/show`}
        title={this.year.show.title}
        asset={this.website.homepageMainBlockImage}
        ticker={this.website.homepageMainBlockTicker}
        homepageMainBlockHideTitle={this.website.homepageMainBlockHideTitle}
        homepageMainBlockDisableLink={this.website.homepageMainBlockDisableLink}
      />
      
      { !this.website.homepageHideYear && (
        <section className="homepage-grade-block__container">
          { this.year.grade5 &&  <HomepageGradeBlock { ...this.year.grade5 } year={this.year.year} language={this.website.language} />}
          { this.year.grade4 &&  <HomepageGradeBlock { ...this.year.grade4 } year={this.year.year} language={this.website.language} />}
          { this.year.grade3 &&  <HomepageGradeBlock { ...this.year.grade3 } year={this.year.year} language={this.website.language} />}
          { this.year.grade2 &&  <HomepageGradeBlock { ...this.year.grade2 } year={this.year.year} language={this.website.language} />}
          { this.year.grade1 &&  <HomepageGradeBlock { ...this.year.grade1 } year={this.year.year} language={this.website.language} />}
        </section>
      ) }
      
      <section className="homepage__links">
        <Link to={`/${this.website.language}/about`}>{this.website.about.title}</Link>
      </section>
    </>
  )
}