import React from 'react';
import classNames from 'classnames';
import { Asset } from '../asset';
import { Ticker } from '../ticker';

export class HomepageIntro extends React.Component {
  constructor (props) {
    super(props);
    this.state = { };
  }

  get className () {
    return classNames({
      'container': true,
      'homepage-intro': true,
      'homepage-intro--hide-title': !!this.props.homepageMainBlockHideTitle,
      'homepage-intro--disable-link': !!this.props.homepageMainBlockDisableLink
    });
  }

  render = () => (
    <a className={this.className} href={this.props.href}>
      <Asset className="homepage__asset" asset={this.props.asset} />
      <h1 className="homepage-intro__title">{ this.props.title }</h1>
      <div className="homepage__spinning-line" />
      <div className="homepage__spinning-line homepage__spinning-line--bottom" />
      <div className="homepage__ticker">
        <Ticker>{ this.props.ticker }</Ticker>
      </div>
    </a>
  )
}